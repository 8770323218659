import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { countries } from '../data';
import CalendlyBooking from './calendly';
import { color } from '../data';
import SuccessModal from './Newsletter/successModal';
import useEmailValidation from './emailValidation';

interface ContactModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ContactModal: React.FC<ContactModalProps> = ({ isOpen, onClose }) => {
  const BaseURL = process.env.REACT_APP_AUTH_BASE_URL;
  const [formData, setFormData] = useState({
    first: '',
    lastName: '',
    businessType: '',
    email: '',
    phoneNumber: '',
    about: '',
    occupation: '',
    country: '',
  });
  const [showCalendly, setShowCalendly] = useState(false);

  // Use the new email validation hook
  const {
    email,
    setEmail,
    handleEmailChange: baseHandleEmailChange,
    handleEmailValidation,
    isValidating,
    errorMessage,
  } = useEmailValidation(
    BaseURL || '',
    'This email is currently associated with an individual account. Please provide a business email address.'
  );

  // Track when user has finished typing email
  const [emailTouched, setEmailTouched] = useState(false);

  // Sync the email in the main formData with the email in the hook
  useEffect(() => {
    setFormData((prev) => ({ ...prev, email }));
  }, [email]);

  // Enhanced email change handler
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    baseHandleEmailChange(e);
    setEmailTouched(false);
  };

  // Handle email validation when user stops typing
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (emailTouched && email) {
      timeoutId = setTimeout(async () => {
        await handleEmailValidation(email);
      }, 500); // 500ms delay after typing stops
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [emailTouched, email, handleEmailValidation]);

  const handleBlur = () => {
    setEmailTouched(true);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Validate email before submission
    const isEmailValid = await handleEmailValidation(email);
    if (!isEmailValid) return;

    try {
      const response = await fetch(
        `${BaseURL}/authentication/business-acquisition/contact-us`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        }
      );
      if (response.status === 201) {
        toast.success('Form submitted successfully');
        setShowCalendly(true);
        setFormData({
          first: '',
          lastName: '',
          email: '',
          businessType: '',
          phoneNumber: '',
          about: '',
          occupation: '',
          country: '',
        });
        onClose();
      }
      if (!response.ok || response.status !== 201) {
        response.json().then((data) => {
          toast.error(data?.response?.message);
        });
      }
    } catch (error) {
      if (error instanceof Error) {
        const errorMessage = error.message;
        if (errorMessage.includes('We already have your information on file')) {
          toast.error(
            'We already have your information on file, and a team member will be in touch with you shortly.'
          );
        } else {
          toast.error(`Error: ${errorMessage}`);
        }
      } else {
        toast.error('An unknown error occurred');
      }
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel="Contact Modal"
        className="bg-white py-2 px-8 rounded-lg shadow-lg w-full max-w-[729px] mx-auto z-50 h-[auto] outline-none"
        overlayClassName="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-center justify-center z-40 outline-none"
      >
        {/* Close button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-3xl text-gray-500"
        >
          &times;
        </button>

        <h2 className="text-[1rem] md:text-[3rem] text-center font-medium">
          Get In Touch
        </h2>
        <form onSubmit={handleSubmit} className="contact-form">
          <div className="flex flex-col md:flex-row justify-between w-full md:space-y-0 md:space-x-4">
            <div className="mb-4 w-full md:w-1/2">
              <label htmlFor="first" className="">
                First Name *
              </label>
              <input
                type="text"
                id="first"
                name="first"
                value={formData.first}
                onChange={handleChange}
                className="border rounded-lg px-3 py-2 w-full"
                placeholder="Enter your first name"
                required
              />
            </div>
            <div className="mb-4 w-full md:w-1/2">
              <label htmlFor="lastName" className="">
                Last Name *
              </label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                className="border rounded-lg px-3 py-2 w-full"
                placeholder="Enter your last name"
                required
              />
            </div>
          </div>
          <div className="mb-4">
            <label htmlFor="occupation" className="">
              Occupation *
            </label>
            <input
              type="text"
              id="occupation"
              name="occupation"
              value={formData.occupation}
              onChange={handleChange}
              className="border rounded-lg px-3 py-2 w-full"
              placeholder="Enter your occupation"
              required
            />
          </div>

          <div className="mb-4">
            <label htmlFor="company" className="">
              Business/Company/Brand Name *
            </label>
            <select
              id="businessType"
              name="businessType"
              value={formData.businessType}
              onChange={handleChange}
              className="border rounded-lg px-3 py-4 w-full"
              required
            >
              <option value="">Select a business type</option>
              <option value="business">Business</option>
              <option value="corporate">Corporate</option>
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="contactEmail" className="">
              Contact Email *
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={handleEmailChange}
              onBlur={handleBlur}
              className={`border rounded-lg px-3 py-2 w-full ${
                emailTouched && isValidating ? 'opacity-50 cursor-wait' : ''
              }`}
              placeholder="Enter your contact email"
              required
              disabled={isValidating}
            />
            {emailTouched && isValidating && (
              <p className="text-gray-500 text-sm mt-1">Validating email...</p>
            )}
            {errorMessage && (
              <p className="text-red-500 text-sm mt-1">{errorMessage}</p>
            )}
          </div>

          <div className="mb-4">
            <label htmlFor="about" className="">
              Country *
            </label>
            <select
              id="country"
              name="country"
              value={formData.country}
              onChange={handleChange}
              className="border rounded-lg px-3 py-4 w-full"
              required
            >
              <option value="">Select a country</option>
              {countries.map((country) => (
                <option key={country.code} value={country.name}>
                  {country.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="phone" className="">
              Phone Number *
            </label>
            <input
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              className="border rounded-lg px-3 py-2 w-full"
              placeholder="xxx-xxx-xxxx"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="about" className="">
              How do you hear about Lightforth?
            </label>
            <select
              id="about"
              name="about"
              value={formData.about}
              onChange={handleChange}
              className="border rounded-lg px-3 py-4 w-full"
              required
            >
              <option value="">Select an option</option>
              <option value="linkedin">LinkedIn</option>
              <option value="google">Google Search</option>
              <option value="onlineAd">Online Ad</option>
              <option value="friend">Friend/Colleague</option>
              <option value="socialMedia">Social Media</option>
              <option value="email">Email Campaign</option>
              <option value="other">Other</option>
            </select>
          </div>
          <button
            type="submit"
            className="bg-[#0094FC] hover:bg-blue-700 text-white font-bold py-3 px-3 rounded w-full mb-4"
          >
            Submit
          </button>
        </form>
      </Modal>
      {showCalendly && (
        <SuccessModal
          setShow={setShowCalendly}
          setShow2={onClose}
          message="Kindly look out for an email from us."
        />
        // <CalendlyBooking
        //   style={{
        //     color: '#fff',
        //     fontSize: '16px',
        //     backgroundColor: color.primary,
        //     border: 'none',
        //     cursor: 'pointer',
        //     borderRadius: '8px',
        //   }}
        //   className="w-auto md:max-w-[300px] px-[30px] py-[15px] my-[1rem] mx-auto md:mx-0"
        //   autoOpen
        // />
      )}
    </>
  );
};

export default ContactModal;
