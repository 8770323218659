// src/components/Sitemap/siteStructure.js
export const siteStructure = {
  home: {
    path: '/',
    label: 'Home',
    children: {
      about: {
        path: '/about',
        label: 'About Us',
      },
      services: {
        path: '#',
        label: 'Services',
        children: {
          autoApply: {
            path: '/auto-apply',
            label: 'Auto Apply',
          },
          atsChecker: {
            path: '/ats-checker',
            label: 'ATS Checker',
          },
          lightChat: {
            path: '/light-chat',
            label: 'Light Chat',
          },
          lightInterview: {
            path: '/light-interview',
            label: 'Light Interview',
          },
          lightResume: {
            path: '/light-resume',
            label: 'Resume Builder',
          },
        },
      },
      business: {
        path: '/business',
        label: 'Business Solutions',
      },
      copilot: {
        path: '/copilot',
        label: 'Copilot',
      },
      career: {
        path: '/career',
        label: 'Career Opportunities',
      },
      resources: {
        path: '#',
        label: 'Resources',
        children: {
          blogs: {
            path: '/blogs',
            label: 'Blogs',
          },
          faq: {
            path: '/faq',
            label: 'FAQ',
          },
          pricing: {
            path: '/pricing',
            label: 'Pricing',
          },
        },
      },
      legal: {
        path: '#',
        label: 'Legal',
        children: {
          terms: {
            path: '/terms-condition',
            label: 'Terms & Conditions',
          },
          privacyStatement: {
            path: '/privacy-statement',
            label: 'Privacy Statement',
          },
          privacyPolicy: {
            path: '/privacy-policy',
            label: 'Privacy Policy',
          },
          cookie: {
            path: '/cookie',
            label: 'Cookie Policy',
          },
        },
      },
      contact: {
        path: '/get-in-touch',
        label: 'Get in Touch',
      },
    },
  },
};
