/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import NewsletterModal from './Newsletter/newsletterModal';
import {
  FaInstagram,
  FaFacebook,
  FaLinkedin,
  FaArrowRight,
} from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { useLocation } from 'react-router-dom';
import CalendlyBooking from './calendly';
import ContactModal from './contactModal';

const Footer = () => {
  const [show, setShow] = React.useState(false);
  const [show2, setShow2] = React.useState(false);
  const location = useLocation();

  return (
    <div className="flex flex-col md:flex-col relative">
      {/* Top Part */}
      <div className="flex flex-col md:flex-row">
        <div
          className="flex-[60%] px-8 md:px-16 lg:px-40 py-12 md:py-16 relative"
          style={{
            backgroundColor:
              location.pathname === '/business' ? '#085058' : '#0A2A61',
          }}
        >
          <div className="max-w-[600px]">
            <h1 className="text-white text-[40px] md:text-[52px] font-medium leading-[1.1] md:leading-[57.2px] mb-4">
              {location.pathname === '/business'
                ? 'Use LightForth To Grow Your Business'
                : 'Ready to Turn Your Job Search Into Success?'}
            </h1>
            <p className="text-gray-300 text-[18px] leading-relaxed mb-8">
              {location.pathname === '/business'
                ? 'Take advantage of our high-powered tools and show your students how to get result 10X faster.'
                : `Transform the way you approach job hunting. With Lightforth, you'll secure interviews faster, land roles that truly match your skills, and streamline your entire job search process.`}
            </p>

            {/* Get Started Today Button with right arrow */}
            {location.pathname === '/business' && (
              <button
                // href="https://app.lightforth.org/auth-landing"
                onClick={() => setShow(true)}
                className="bg-[#22DF72] text-[#085058] font-medium mb-[28px] px-[24px] py-[14px] mt-4 rounded block"
                style={{
                  width: 'fit-content',
                }}
              >
                Get Started Today
                <FaArrowRight
                  style={{ display: 'inline', marginLeft: '.5rem' }}
                />
              </button>
              // <CalendlyBooking
              //   style={{
              //     color: '#fff',
              //     fontSize: '16px',
              //     backgroundColor: '#22DF72',
              //     border: 'none',
              //     cursor: 'pointer',
              //     borderRadius: '8px',
              //   }}
              //   className="w-auto md:max-w-[300px] px-[30px] py-[10px] md:px-[15px] md:py[10px] my-[1rem] mx-auto md:mx-0"
              // />
            )}
            {/* <div className="flex flex-row sm:flex-row gap-4 mb-8">
              <button className="bg-black text-[#0A2A61] px-3 py-3 rounded-lg font-semibold hover:bg-gray-100 transition-colors">
                <div className="flex items-center">
                  <img
                    src="/asset/icons/apple.png"
                    alt="Profile Icon"
                    className="w-[25px] h-[25px] mr-3"
                  />
                  <div className="flex flex-col">
                    <span className="font-semibold text-[10px] leading-[12px] text-gray-500">
                      DOWNLOAD ON THE
                    </span>
                    <span className="font-normal text-[18px] text-left leading-[16px] text-white">
                      App Store
                    </span>
                  </div>
                </div>
              </button>
              <button className="bg-black text-[#0A2A61] px-3 py-3 rounded-lg z-[20] font-semibold hover:bg-gray-100 transition-colors">
                <div className="flex items-center">
                  <img
                    src="/asset/icons/play.png"
                    alt="Profile Icon"
                    className="w-[25px] h-[25px] mr-3"
                  />
                  <div className="flex flex-col">
                    <span className="font-semibold text-[10px] text-left leading-[12px] text-gray-500">
                      GET IT ON
                    </span>
                    <span className="font-normal text-[18px] text-left leading-[16px] text-white">
                      Google Play
                    </span>
                  </div>
                </div>
              </button>
            </div> */}
            <p className="text-gray-300 text-[16px] mb-[13px]">
              Trusted by 50k+{' '}
              {location.pathname === '/business' ? 'Businesses' : 'customers'}
            </p>
            <div className="flex items-center">
              <img
                src="/asset/icons/footerStar.png"
                alt="Footer Star"
                className="mr-2"
              />
              <span className="text-gray-300 text-[16px] leading-[20px]">
                4.4/5 • 3,841 Reviews
              </span>
            </div>
          </div>

          {/* Floating icon */}
          <img
            src="/asset/icons/footerArrow.png"
            alt="Footer icon"
            className="absolute hidden md:block bottom-8 right-8 md:bottom-12 md:right-12 max-w-[161px] max-h-[161px] z-[1]"
          />
        </div>

        {/* Right half */}
        <div className="flex-[40%]">
          <img
            src={
              location.pathname === '/business'
                ? '/asset/images/bizzFoot.png'
                : '/asset/images/footer.png'
            }
            alt="Footer illustration"
            className="w-full h-full object-cover"
          />
        </div>
      </div>

      {/* Bottom Part */}
      <div className="mt-[107px] mx-auto py-10">
        <div className="flex flex-col md:flex-row container mx-auto px-4 md:px-10 lg:px-20">
          {/* First Column */}
          <div className="flex-1 md:flex-[2] text-black flex flex-col items-start sm:mr-44">
            <img
              src="/asset/icons/footerLogo.png"
              alt="Logo"
              className="w-[153px] h-auto mb-4"
            />
            <p className="text-[16px] leading-relaxed text-left mb-[20px]">
              LightForth is poised to revolutionize the career development
              landscape through a platform that provides personalized,
              end-to-end support for users across their professional journey.
            </p>
            <div className="flex space-x-4 mt-4">
              <a
                href="https://www.instagram.com/iamlightforth/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram className="text-gray text-2xl hover:text-gray-400 transition-colors" />
              </a>
              <a
                href="https://m.facebook.com/Lightforth.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebook className="text-gray text-2xl hover:text-gray-400 transition-colors" />
              </a>
              <a
                href="https://twitter.com/joinLightforth"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaXTwitter className="text-gray text-2xl hover:text-gray-400 transition-colors" />
              </a>
              <a
                href="https://www.linkedin.com/company/lightforth"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin className="text-gray text-2xl hover:text-gray-400 transition-colors" />
              </a>
            </div>
          </div>
          {/* Second Column */}
          <div className="flex-1 md:flex-[1] text-black flex flex-col items-start">
            <h2 className="font-bold text-black mb-[10px] md:mb-[30px] text-left mt-[2rem] md:mt-0">
              Company
            </h2>
            <ul className="text-left">
              <li className="mb-[15px]">
                <a href="/about" className="hover:underline">
                  About Us
                </a>
              </li>
              <li className="mb-[15px]">
                <a href="/career" className="hover:underline">
                  Career
                </a>
              </li>
              <li className="mb-[15px]">
                <a href="/pricing" className="hover:underline">
                  Pricing
                </a>
              </li>
            </ul>
          </div>
          {/* Third Column */}
          <div className="flex-1 md:flex-[1] text-black flex flex-col items-start">
            <h2 className="font-bold text-black mb-[10px] md:mb-[30px]text-left">
              Services
            </h2>
            <ul className="text-left">
              <li className="mb-[15px]">
                <a href="/light-resume" className="hover:underline">
                  Resume Builder
                </a>
              </li>
              <li className="mb-[15px]">
                <a href="auto-apply" className="hover:underline">
                  Auto Job Application
                </a>
              </li>
              <li className="mb-[15px]">
                <a href="/light-interview" className="hover:underline">
                  Interview Prep
                </a>
              </li>
              <li className="mb-[15px]">
                <a href="/light-chat" className="hover:underline">
                  AI Companion
                </a>
              </li>
              <li className="mb-[15px]">
                <a href="/ats-checker" className="hover:underline">
                  ATS Checker
                </a>
              </li>
            </ul>
          </div>
          {/* Fourth Column */}
          <div className="flex-1 md:flex-[1] text-black flex flex-col items-start">
            <h2 className="font-bold text-black mb-[10px] md:mb-[30px]text-left">
              Resources
            </h2>
            <ul className="text-left">
              <li className="mb-[15px]">
                <a
                  href="https://blog.lightforth.org/"
                  className="hover:underline"
                >
                  Blog
                </a>
              </li>
              <li
                className="mb-[15px]"
                onClick={() => {
                  setShow(true);
                }}
              >
                <a href="#" className="hover:underline">
                  Newsletter
                </a>
              </li>
              <li className="mb-[15px]">
                <a href="/faq" className="hover:underline">
                  FAQ
                </a>
              </li>
              <li className="mb-[15px]">
                <a
                  href="https://quiz.lightforth.org/"
                  className="hover:underline"
                >
                  Take a Quiz
                </a>
              </li>
              <li className="mb-[15px]">
                <a href="/sitemap" className="hover:underline">
                  Sitemap
                </a>
              </li>
            </ul>
          </div>
          {/* Fifth Column */}
          <div className="flex-1 md:flex-[1] text-black flex flex-col items-start">
            <h2 className="font-bold text-black mb-[10px] md:mb-[30px]text-left">
              Contact
            </h2>
            <ul className="text-left">
              <li className="mb-[15px]">
                <a href="/get-in-touch" className="hover:underline">
                  Help Center
                </a>
              </li>
              <li className="mb-[15px]">
                <a href="tel:+17743734206" className="hover:underline">
                  +1-774-373-4206
                </a>
              </li>
              <li className="mb-[15px]">
                <a
                  href="mailto:support@lighforth.org"
                  className="hover:underline"
                >
                  support@lightforth.org
                </a>
              </li>
              <li className="mb-[15px]">
                <p>14160 Dallas Parkway, ste 760, Dallas Texas 75254.</p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="flex mx-auto w-[90%] mt-[100px] mb-[100px] border-t border-gray-300 justify-between items-center mt-4 pb-[10] text-gray-600">
        <div className="mt-[50px]">
          <span className="text-sm mt-[30px]">
            Copyright © 2024 LightForth. All Rights Reserved
          </span>
        </div>
        <div className="flex items-center space-x-2 mt-[30px]">
          <a href="/terms-condition" className="text-sm hover:underline">
            Terms & Conditions
          </a>
          <span className="text-gray-400">•</span>
          <a href="privacy-policy" className="text-sm hover:underline">
            Privacy Policy
          </a>
        </div>
      </div>
      {/* <NewsletterModal
        show={show}
        setShow={setShow}
        show2={show2}
        setShow2={setShow2}
      /> */}
      <ContactModal isOpen={show} onClose={() => setShow(false)} />
    </div>
  );
};

export default Footer;
