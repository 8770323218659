import React from 'react';
import { Link } from 'react-router-dom';
import { siteStructure } from './siteStructure';

interface SiteStructureItem {
  path: string;
  label: string;
  children?: Record<string, SiteStructureItem>;
}

const renderSitemapItem = (
  item: SiteStructureItem,
  key: string,
  level: number = 0
): JSX.Element => {
  const padding = `${level * 1.5}rem`;

  return (
    <div key={key} style={{ paddingLeft: padding }} className="py-2">
      {item.path === '#' ? (
        <span className="font-semibold text-gray-700 text-lg">
          {item.label}
        </span>
      ) : (
        <Link
          to={item.path}
          className="text-blue-600 hover:text-blue-800 hover:underline transition-colors duration-200"
        >
          {item.label}
        </Link>
      )}
      {item.children && (
        <div className="mt-2">
          {Object.entries(item.children).map(([childKey, childItem]) =>
            renderSitemapItem(childItem, childKey, level + 1)
          )}
        </div>
      )}
    </div>
  );
};

export const Sitemap = () => {
  return (
    <div className="min-h-screen bg-gray-50 pb-[3rem] pt-[3rem]">
      <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-sm my-8">
        <h1 className="text-3xl font-bold mb-8 text-gray-800">Site Map</h1>
        <div className="space-y-4">
          {Object.entries(siteStructure).map(([key, item]) =>
            renderSitemapItem(item, key)
          )}
        </div>
      </div>
    </div>
  );
};

export default Sitemap;
